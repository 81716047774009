<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5 15V9.13051C16.5 8.72215 16.4167 8.31808 16.2551 7.94306C16.0935 7.56803 15.8571 7.22995 15.5602 6.94951L10.0305 1.72351C9.75199 1.4602 9.38327 1.31349 9 1.31349C8.61673 1.31349 8.248 1.4602 7.9695 1.72351L2.43975 6.94951C2.14292 7.22995 1.90646 7.56803 1.74487 7.94306C1.58328 8.31808 1.49996 8.72215 1.5 9.13051V15C1.5 15.3978 1.65804 15.7794 1.93934 16.0607C2.22064 16.342 2.60218 16.5 3 16.5H15C15.3978 16.5 15.7794 16.342 16.0607 16.0607C16.342 15.7794 16.5 15.3978 16.5 15Z"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {
  name: 'FiveHomeIcon',
}
</script>
